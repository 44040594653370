import request from '@/utils/request';
import type { ResponseStructure } from '@/utils/request';
import type { SettlementType } from '@/utils/enum';
import type { Password } from '@/components/RightContent/ModifyPassowrd';
import { API_USER, API_SMB } from '@/utils/utils';
import { RegisterParams, SmsCodeType } from '@/pages/User/interface';
import { encrypt } from '@bluevision/tools';

/** 获取用户信息 */
export async function getUserInfo() {
  return request<ResponseStructure<API.UserInfo>>(
    `${API_SMB}/user/get_user_info`,
  );
}

/** 获取企业信息 */
export async function getBusinessInfo() {
  return request<ResponseStructure<API.BusinessInfo<SettlementType>>>(
    `${API_SMB}/user/get_business_info`,
  );
}

/** 修改企业信息 */
export async function updateBusinessInfo(
  data: Partial<API.BusinessInfo<SettlementType>>,
) {
  return request<ResponseStructure>(`${API_SMB}/user/update_business_info`, {
    method: 'POST',
    data,
  });
}

export type LoginInfo = {
  username: string;
  password: string;
  remember: 0 | 1;
  email_code: string; //邮箱验证码
  ticket: string; // 前端回调函数返回的用户验证票据
  randstr: string; // 前端回调函数返回的随机字符串
};
/** 登录 */
export async function login(data: LoginInfo) {
  return request<ResponseStructure>(`${API_USER}/login`, {
    method: 'POST',
    data: {
      ...data,
    },
  });
}

/** 激活邮件重发 */
export async function resendActiveEmailApi(data: { username: string }) {
  return request<ResponseStructure>(`${API_USER}/active_email_resend`, {
    method: 'POST',
    data,
  });
}

/** 激活 */
export async function active(data: {
  email: string;
  password?: string;
  role: string;
  access_token: string;
  rid: string;
}) {
  return request<ResponseStructure>(`${API_USER}/active`, {
    method: 'POST',
    data: {
      ...data,
      remember: 1,
    },
  });
}

/** 注册 */
export async function register(data: RegisterParams) {
  return request<ResponseStructure>(`${API_USER}/register`, {
    method: 'POST',
    data,
  });
}

/** 忘记密码 */
export async function forget(params: { username: string }) {
  return request<ResponseStructure>(`${API_USER}/reset_password`, {
    method: 'GET',
    params,
  });
}

/** 重置密码 */
export async function reset(data: {
  rid: string;
  token: string;
  password: string;
  re_password: string;
}) {
  return request<ResponseStructure>(`${API_USER}/reset_password`, {
    method: 'POST',
    data,
  });
}

/** 检查邮箱激活状态 */
export async function checkActive(data: { username: string }) {
  return request<ResponseStructure<{ is_active: 0 | 1 }>>(
    `${API_USER}/check_user_active`,
    {
      method: 'POST',
      data,
    },
  );
}

/** 修改个人信息 */
export async function updateUserInfo(data: API.UserInfo) {
  return request<ResponseStructure<API.UserInfo>>(
    `${API_SMB}/user/update_user_info`,
    {
      method: 'POST',
      data,
    },
  );
}

/** 修改密码 */
export async function modifyPassword(data: Password) {
  return request<ResponseStructure>(`${API_USER}/modify_password`, {
    method: 'POST',
    data,
  });
}

/** 退出登录 */
export async function logout() {
  return request<ResponseStructure>(`${API_USER}/logout`);
}

/** 获取当前企业用户资产监控提醒 */
export async function getAssetMonitorInfo() {
  return request(`${API_SMB}/user/page_quality_info`);
}

/** 当前企业用户资产监控提醒点击 */
export async function setAssetMonitor() {
  return request(`${API_SMB}/user/page_quality_info`, {
    method: 'POST',
  });
}

// 腾讯云验证码验证公钥
const tencentCaptchaPublicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAm+DHaL0VtyT+SsBAcJYj
HyCa4jmwBoFDIOECkoQvyqi7DNkKCs4UIM/FaCitaYBd6+rhZFYwSVvncNdjwOHw
EkmVp63EPybgmuoSLXK1Z4au8SZyUeqXl8U1cSysAjQ6PI/FudA7BS/GCfayLOty
I2LKjNzbG5lL7vUpcMQw8bIx+T0Du8zmWCJTyQXwAGX7EqYqiW+ybt7o5ZRq7LTT
sABwz7ZCmSQIXnwajy9odIhgqt1x8ZMrOVQ1QusaIYIKg1aDqyh442vItthk98+N
0qf9UFYJCW/LZ08R6ASXPNou0GaRmPTLaPzp3b9cijITuC0CBIOydRycJ6QKQiDw
9wIDAQAB
-----END PUBLIC KEY-----`;

// TencentCaptchaKey参数
export const getCaptchaEncryptObj = () => {
  const origin_data = Date.now() + Math.random().toString(16).slice(2);
  const encrypted_data = encrypt(origin_data, tencentCaptchaPublicKey);
  return {
    origin_data,
    encrypted_data,
  };
};

/** 获取短信验证码 */
export async function getCaptcha(params: {
  phone: string;
  code_type: SmsCodeType;
  ticket: string;
  randstr: string;
}) {
  return request<ResponseStructure>(`${API_SMB}/user/get_remind_captcha`, {
    method: 'GET',
    params: { ...params, ...getCaptchaEncryptObj() },
  });
}

/** 同意协议 */
export async function agreeProtocol() {
  return request<ResponseStructure>(`${API_SMB}/user/agree_protocol`);
}

// 滑块验证接口
export const verifyCaptcha = (data: API.VerifyCaptcha) => {
  return request<ResponseStructure>(`${API_SMB}/user/describe_captcha`, {
    method: 'POST',
    data: { ...data, ...getCaptchaEncryptObj() },
  });
};

// 腾讯云密钥key
export const getTencentCaptchaKey = () => {
  const data = getCaptchaEncryptObj();
  return request<ResponseStructure<API.TencentCaptchaKey>>(
    `${API_SMB}/user/get_captcha_key`,
    {
      method: 'POST',
      data,
    },
  );
};

// 获取登录规则
export const getLoginRules = (data: { user_email: string }) => {
  return request<ResponseStructure<API.loginRules>>(
    `${API_SMB}/user/get_login_rules`,
    {
      method: 'POST',
      data,
    },
  );
};
// 发送验证码到邮箱
export const sendVerifyCodeToEmail = (data: { user_email: string }) => {
  return request<ResponseStructure<API.loginRules>>(
    `${API_SMB}/user/send_email_code`,
    {
      method: 'POST',
      data,
    },
  );
};
