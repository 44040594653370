import request from '@/utils/request';
import { API_SMB } from '@/utils/utils';
import { IMediaApply } from '@/pages/MediaApplyForm/type';
import { AllPlatform } from '@/utils/enum';
import { ResponseStructure } from '@/utils/request';

// const API_SMB = '';

/** 提交开户申请信息 */
export async function submitAccountApply(
  data: IMediaApply.Request.SubmitAccountApplyParams,
) {
  return request(`${API_SMB}/entity/account_apply`, {
    method: 'POST',
    data,
  });
}

/** 小媒体提交开户申请信息 */
export async function submitSmallAccountApply(
  data: IMediaApply.Request.SmallMediaForm,
) {
  return request(`${API_SMB}/entity/new_platform_apply`, {
    method: 'POST',
    data,
  });
}

/** 获取开户行业/产品类型列表 - TT/Snap */
export async function getIndustry(params: {
  platform: IMediaApply.Request.RequestAllPlatforms | AllPlatform;
}) {
  return request<ResponseStructure<IMediaApply.Response.CommonSelectList>>(
    `${API_SMB}/entity/get_industry`,
    {
      params,
    },
  );
}

/** 获取开户时区 */
export async function getAllTimeZone(params: {
  platform: IMediaApply.Request.RequestAllPlatforms | AllPlatform;
}) {
  return request<ResponseStructure<IMediaApply.Response.CommonSelectList>>(
    `${API_SMB}/entity/get_all_timezone`,
    {
      params,
    },
  );
}

/** 获取投放国家 -- TT/SNAP */
export async function getAllRegisteredArea(params: { platform: AllPlatform }) {
  return request<ResponseStructure<IMediaApply.Response.CommonSelectList>>(
    `${API_SMB}/entity/get_all_registered_area`,
    {
      params,
    },
  );
}

/** 获取七牛Token */
export async function getQiniuToken() {
  return request<ResponseStructure<IMediaApply.Response.GetQiniuToken>>(
    `${API_SMB}/entity/get_q_upload_token`,
  );
}

/** Tiktok资质证书上传 */
export async function submitTiktokLicenseUpload(
  data: IMediaApply.Request.LicenseLink,
) {
  return request<ResponseStructure<IMediaApply.Response.GetLicenseImageId>>(
    `${API_SMB}/entity/tt_license_upload`,
    {
      method: 'POST',
      data,
    },
  );
}

/** 营业执照识别 */
export async function getImageVerify(data: IMediaApply.Request.LicenseLink) {
  return request<ResponseStructure<IMediaApply.Response.ImageVerify>>(
    `${API_SMB}/entity/image_ocr_verify`,
    {
      method: 'POST',
      data,
    },
  );
}
// 获取银联验证接口
export async function getCheckUnipay(license_no: string) {
  return request(
    `${API_SMB}/entity/tt_unionpay_info_check?license_no=${license_no}`,
  );
}
