/** 禁止使用浏览器相关API，文件会被config引用，config会在node环境运行，所以不支持浏览器相关API */
import { createFromIconfontCN } from '@ant-design/icons';
import type { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { customAlphabet } from 'nanoid';
import qs from 'qs';
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import { ResponseStructure } from './request';
import { errorMessage } from './config';
import { SelectProps } from 'antd';
import { AllPlatform } from './enum';
import { useTranslation } from 'react-i18next';
import i18n, { LANGUAGE_FLAG_MAP, Locales } from '@/i18n';

export const API_SMB = '/api/smb/business';
export const API_USER = '/api/smb/business/user';

export const IconFontUrl = '//at.alicdn.com/t/c/font_3545865_765xijo8e4g.js';
export const IconFont = createFromIconfontCN({
  scriptUrl: IconFontUrl,
});

/** 表格触发排序，处理字段通用方法 */
export const sorterFields = <T = unknown>(
  sorter: SorterResult<T> | SorterResult<T>[],
) => {
  /** 触发多列排序 */
  if (Array.isArray(sorter)) {
    return sorter.map((item) => {
      if (item.order === 'ascend') return item.column?.dataIndex;
      return `-${item.column?.dataIndex}`;
    });
  }

  /** 触发单列排序 */
  if (Object.keys(sorter).length > 0) {
    if (!sorter.column?.dataIndex) return undefined;
    if (sorter.order === 'ascend') return [sorter.column?.dataIndex];
    return [`-${sorter.column?.dataIndex}`];
  }
  return undefined;
};

/** 拼接跳转到Facebook主页链接 */
export const facebookHomepage = (suffix: string | number) => {
  return `https://www.facebook.com/${suffix}`;
};

/** 初始化日期方法
 *  moment转换成字符串
 */
export const transformMoment = (dates: [moment.Moment, moment.Moment]) => {
  const [startDate, endDate] = (dates || []).map((date) =>
    date ? date?.format('YYYY-MM-DD') : undefined,
  );
  return {
    startDate,
    endDate,
  };
};

/** google event 埋点 */

declare const window: Window & {
  gtag: unknown;
};

export const gtagEventBuriedPoint = (
  action: string,
  properties: Record<string, unknown>
) => {
  if (window.gtag) {
    // @ts-ignore add TS check, handle history code
    window.gtag('event', action, properties);
  }
};

/** google config 埋点 */
export const gtagConfigBuriedPoint = (params: unknown) => {
  if (window.gtag) {
    // @ts-ignore add TS check, handle history code
    window.gtag('config', 'G-6DYPG8PK4J', params);
  }
};

/** 通用：判断接口是否请求成功 */
export const fetchSuccess = (response: ResponseStructure<unknown>): boolean => {
  return (
    response.code === '000000' || // 新版成功
    response.success === 1 // 老版成功
  );
};

/** 拼接七牛地址 */
export const getQiniuFileUrl = (hash: string) => {
  return `http://luban-static.domobcdn.com/${hash}`;
};

/** 通用：基础处理接口请求失败错误 */
export const fetchErrorHandler = (res: ResponseStructure<unknown>) => {
  // @ts-ignore 浏览器非200结构
  if (res?.response?.status === 401) {
    return;
  }

  errorMessage(res?.info);
  Sentry.captureException(res?.info);
};

/** 默认页数、页码 */
export const defaultPage = { pageSize: 10, current: 1 };

/**
 * 计算元素相对于祖先元素偏离的 offsetTop
 */
export const findOffsetTop = (
  element: HTMLDivElement | null,
  ancestor: HTMLDivElement | null,
) => {
  if (!element || !ancestor || !ancestor.contains(element)) {
    return 0;
  }
  let offsetTop = 0;
  let node = element;
  while (node && ancestor && node !== ancestor) {
    offsetTop += node.offsetTop;
    node = node.offsetParent as HTMLDivElement;
  }
  return offsetTop;
};

/** 将Select Options数据源转换成label、value的形式,目的统一下拉搜索 */
export const changeFields = <T = unknown>(
  dataSources: T,
  { label, value }: { label: string | number; value: string | number },
) => {
  if (!Array.isArray(dataSources)) return [];

  return dataSources.map((field) => ({
    label: field[label],
    value: field[value],
    ...field,
  }));
};

/** 下拉框搜索 */
export const filterInputSearch: SelectProps['filterOption'] = (
  input: string,
  option,
) => {
  return ((option?.label as string) ?? '')
    .toLowerCase()
    .includes(input.toLowerCase()?.trim());
};

/** 近一个月 */
export const nearlyOneMonth = [moment().add(-30, 'days'), moment()];

/** 链接换行截取 */
export const splitLink = (links: string) => {
  return links
    ? links
      .split(/[\r\n↵]/)
      .filter((link) => link.trim())
      .map((link) => link.trim())
    : undefined;
};

/** 多个链接合并为带换行符的字符串 */
export const joinLinks = (links?: string[]) => {
  return links?.length 
    ? links
      .filter((link) => link?.trim())
      .join('\n')
    : undefined;
};

/** 根据名称获取code */
export const getCodeByName = (list: Array<{name: string, code: string}>, name: string): string | undefined => {
  const item = list.find(item => item.name === name);
  return item?.code;
}

/** 根据名称获取code（多选） */
export const getCodeByNameList = (list: { code: string; name: string }[], names: string[] | undefined) => {
  return names?.map(name => {
    const item = list.find(item => item.name === name);
    return item?.code || '';
  });
};

/** 请求接口的随机ID */
export const getRequestId = () => {
  const nanoid = customAlphabet('1234567890', 6);

  return `${Date.now()}${nanoid()}`;
};

/** 请求接口的Host地址 */
export const HOST_URL = 'luban-plus.bluemediagroup.cn';

export const getRequestHeader = () => {
  return {
    'Smb-Request-Id': getRequestId(),
    'Smb-Request-Host': HOST_URL,
    'Accept-Language': LANGUAGE_FLAG_MAP[i18n.language as Locales],
  };
}

export const requestHeader = {
  'Smb-Request-Id': getRequestId(),
  'Smb-Request-Host': HOST_URL,
  'Accept-Language': LANGUAGE_FLAG_MAP[i18n.language as Locales],
};

/** 根据枚举展示不同的媒体名称 */
export const transferPlatformMap: Record<AllPlatform, string> = {
  META: 'Facebook',
  TIKTOK: 'Tiktok',
  GOOGLE: 'Google',
  SNAPCHAT: 'Snapchat',
};

/** 给文件名称拼接时间 */
export const addDateToFilename = (
  filename: string,
  startDate?: string,
  endDate?: string,
) => {
  if (!startDate || !endDate) {
    return filename;
  }
  return `${filename}_${startDate}_${endDate}`;
};

/** 判断loading队列
 *  全为false，则关闭loading，返回false
 *  有个一个为true，则返回true
 */
export const loadingTrain = (loadingList: boolean[]) => {
  return loadingList.some((item) => item === true);
};

/** 获取url地址上的参数 */
export const getUrlParams = (url: string) => {
  const index = url?.indexOf('?');

  if (index === -1) return {};

  const params = url.slice(index + 1);

  return qs.parse(params);
};

// 数组 的 field 字段 国际化
export const useI18nTransform = <T>(options: T[], field: string): T[] => {
  const { t } = useTranslation();

  return options?.map((item) => ({
    ...item,
    [field]: typeof item?.[field] === 'string' ? t(item?.[field]) : item?.[field],
  })) ?? [];
};

interface SelectOptions {
  label: string;
  value: string | number;
}
// Select 的 options label 国际化
export const useSelectLabelI18n = (options: SelectOptions[]) => useI18nTransform(options, 'label');

// Table 的 columns title 国际化
export const useTableColumnI18n = <T>(options: ColumnsType<T>): ColumnsType<T> => useI18nTransform(options, 'title');


// 传入值是否为非空字符串
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNonEmptyString = (value: any): value is string => {
  return typeof value === 'string' && value.trim() !== '';
}
