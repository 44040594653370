// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/data1/home/gitlab-runner/builds/Ya4axh5y/0/smb/smb_mono/apps/smb_web_plus/src/models/useAssetMonitor';
import model_2 from '/data1/home/gitlab-runner/builds/Ya4axh5y/0/smb/smb_mono/apps/smb_web_plus/src/models/useExchangeRate';
import model_3 from '/data1/home/gitlab-runner/builds/Ya4axh5y/0/smb/smb_mono/apps/smb_web_plus/src/models/useMedia';
import model_4 from '/data1/home/gitlab-runner/builds/Ya4axh5y/0/smb/smb_mono/apps/smb_web_plus/src/models/useOpenAccountUrl';
import model_5 from '/data1/home/gitlab-runner/builds/Ya4axh5y/0/smb/smb_mono/apps/smb_web_plus/src/models/usePolicyInfo';
import model_6 from '/data1/home/gitlab-runner/builds/Ya4axh5y/0/smb/smb_mono/apps/smb_web_plus/src/models/useWallet';
import model_7 from '/data1/home/gitlab-runner/builds/Ya4axh5y/0/smb/smb_mono/apps/smb_web_plus/src/models/walkTour';
import model_8 from '/data1/home/gitlab-runner/builds/Ya4axh5y/0/smb/smb_mono/apps/smb_web_plus/src/.umi-production/plugin-initialState/@@initialState';

export const models = {
model_1: { namespace: 'useAssetMonitor', model: model_1 },
model_2: { namespace: 'useExchangeRate', model: model_2 },
model_3: { namespace: 'useMedia', model: model_3 },
model_4: { namespace: 'useOpenAccountUrl', model: model_4 },
model_5: { namespace: 'usePolicyInfo', model: model_5 },
model_6: { namespace: 'useWallet', model: model_6 },
model_7: { namespace: 'walkTour', model: model_7 },
model_8: { namespace: '@@initialState', model: model_8 },
} as const
