// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/data1/home/gitlab-runner/builds/Ya4axh5y/0/smb/smb_mono/apps/smb_web_plus/src/models/accountList';
import model_2 from '/data1/home/gitlab-runner/builds/Ya4axh5y/0/smb/smb_mono/apps/smb_web_plus/src/models/agreeProtocol';
import model_3 from '/data1/home/gitlab-runner/builds/Ya4axh5y/0/smb/smb_mono/apps/smb_web_plus/src/models/authority';
import model_4 from '/data1/home/gitlab-runner/builds/Ya4axh5y/0/smb/smb_mono/apps/smb_web_plus/src/models/grayInfo';
import model_5 from '/data1/home/gitlab-runner/builds/Ya4axh5y/0/smb/smb_mono/apps/smb_web_plus/src/models/openingOutline';
import model_6 from '/data1/home/gitlab-runner/builds/Ya4axh5y/0/smb/smb_mono/apps/smb_web_plus/src/models/openingRecord';
import model_7 from '/data1/home/gitlab-runner/builds/Ya4axh5y/0/smb/smb_mono/apps/smb_web_plus/src/models/requestCount';
import model_8 from '/data1/home/gitlab-runner/builds/Ya4axh5y/0/smb/smb_mono/apps/smb_web_plus/src/models/wallet';
import model_9 from '/data1/home/gitlab-runner/builds/Ya4axh5y/0/smb/smb_mono/apps/smb_web_plus/src/models/workDashboard';

export const models = {
model_1: { namespace: 'accountList', model: model_1 },
model_2: { namespace: 'agreeProtocol', model: model_2 },
model_3: { namespace: 'authority', model: model_3 },
model_4: { namespace: 'grayInfo', model: model_4 },
model_5: { namespace: 'openingOutline', model: model_5 },
model_6: { namespace: 'openingRecord', model: model_6 },
model_7: { namespace: 'requestCount', model: model_7 },
model_8: { namespace: 'wallet', model: model_8 },
model_9: { namespace: 'workDashboard', model: model_9 },
} as const
