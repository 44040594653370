import { getTencentCaptchaKey, verifyCaptcha } from '@/services/user';
import { Message } from '@bluevision/components';
import { useTranslation } from 'react-i18next';
import { useCallback, useRef } from 'react';
import { captchaType } from '@/components/VerifyCodeModal';
import { ResponseStructure } from '@/utils/request';
/** APP_ID */
const CAPTCHA_APP_ID = '191573373';
// 请求成功code
const successCode = '000000';

interface ICaptchaResult {
  ret: number;
  ticket: string;
  randstr: string;
  CaptchaAppId?: string;
  bizState?: string;
  errorCode?: number;
  errorMessage?: string;
}

type VerifyCaptchaFunc = (data: {
  ticket: string;
  randstr: string;
}) => Promise<ResponseStructure<unknown>>;

interface captchaInitType {
  successCb?: (captcha: captchaType) => Promise<void> | void; // 滑动成功回调
  loadSuccessCb?: () => void | Promise<void>; // 加载成功回调
  verifyCaptchaFunc?: VerifyCaptchaFunc; // 验证验证码
}

export default function useCaptcha() {
  const { t, i18n } = useTranslation();

  // 使用 useRef 来存储回调函数
  const successCbRef = useRef<(captcha: captchaType) => void | Promise<void>>();
  const loadSuccessCbRef = useRef<() => void | Promise<void>>();
  const verifyCbRef = useRef<VerifyCaptchaFunc>();

  // 滑块回调
  const captchaCallback = useCallback(
    async (res: ICaptchaResult) => {
      const { ticket, randstr } = res;
      if (ticket && randstr) {
        try {
          const res = verifyCbRef.current
            ? await verifyCbRef.current({ ticket, randstr })
            : await verifyCaptcha({ ticket, randstr });
          if (res.code === successCode) {
            // 成功 需要外部组件传入的方法执行
            // callFn?.({ ticket, randstr });
            successCbRef.current?.({ ticket, randstr }); // 调用存储的回调函数
          } else {
            // 如果有错误信息，提示错误信息
            Message.error(res.info);
          }
        } catch (e) {
          Message.error(t('failed_to_obtain_the_verification_code'));
        }
      }
    },
    [t],
  );
  // 加载失败
  const captchaLoadErrorCallback = useCallback(() => {
    const ticket =
      'trerror_1001_' +
      CAPTCHA_APP_ID +
      '_' +
      Math.floor(new Date().getTime() / 1000);
    captchaCallback({
      ret: 0,
      randstr: '@' + Math.random().toString(36).substr(2),
      ticket: ticket,
      errorCode: 1001,
      errorMessage: 'jsload_error',
    });
  }, [captchaCallback]);

  // 开启滑块验证
  const onCaptchaShow = useCallback(
    (encryptData: string) => {
      try {
        // @ts-expect-error TencentCaptcha无法被识别
        const captcha = new TencentCaptcha(CAPTCHA_APP_ID, captchaCallback, {
          aidEncrypted: encryptData,
          userLanguage: i18n.language,
        });
        captcha.show();
        loadSuccessCbRef.current?.(); // 调用存储的回调函数
      } catch (error) {
        captchaLoadErrorCallback();
      }
    },
    [captchaLoadErrorCallback, captchaCallback, i18n.language],
  );

  const captchaInit = async ({
    successCb,
    loadSuccessCb,
    verifyCaptchaFunc,
  }: captchaInitType) => {
    successCbRef.current = successCb; // 存储回调函数
    loadSuccessCbRef.current = loadSuccessCb; // 存储加载完成回调函数
    verifyCbRef.current = verifyCaptchaFunc;

    const res = await getTencentCaptchaKey();

    if (res.code === successCode) {
      onCaptchaShow(res.data.encrypted_key);
    } else {
      // 如果有错误信息，提示错误信息
      Message.error(res.info);
    }
  };

  return captchaInit;
}
