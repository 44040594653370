/** 钱包 logo */
import walletIcon from '@/assets/images/walletIcon.png';
/** Facebook logo */
import facebookIcon from '@/assets/images/facebookIcon.png';
/** Facebook 圆角logo */
import facebookRadiusIcon from '@/assets/images/facebookRadiusIcon.png';

import alipayIcon from '@/assets/images/alipayIcon.png';
import wechatIcon from '@/assets/images/wechatIcon.png';
import unionPayIcon from '@/assets/images/unionPayIcon.png';
import payoneerIcon from '@/assets/images/payoneerIcon.png';
import googleIcon from '@/assets/images/google.png';
import tiktokIcon from '@/assets/images/tiktok.png';
import snapchatIcon from '@/assets/images/snapchat.png';
import alipay from '@/assets/images/alipay.png';
import wechat from '@/assets/images/wechat.png';
import unionPay from '@/assets/images/unionpay.png';
import payoneer from '@/assets/images/payoneer.png';
import pingpong from '@/assets/images/pingpong.svg';
import pingpongVa from '@/assets/images/pingpongVa.svg';
/** 开户详情营业执照图片加载失败 */
import businessLoadingFailed from '@/assets/images/businessLoadingFailed.png';

/** FB开户申请操作说明封面图 */
import facebookOpenAccountTip from '@/assets/images/facebookOpenAccountTip.png';

/** Tiktok开户申请操作说明封面图 */
import tiktokOpenAccountTip from '@/assets/images/tiktokOpenAccountTip.png';

/** Google开户申请操作说明封面图 */
import googleOpenAccountTip from '@/assets/images/googleOpenAccountTip.png';

/** 空状态图片 */
import emptyIcon from '@/assets/images/empty@2x.png';

/** 广告拒登率为0 */
import adRefuse from '@/assets/images/adRefuse.png';

import { CDN_URL } from '@smb_mono/utils';

import logo from '@/assets/images/home/header-logo.png';
import logoEn from '@/assets/images/home/header-logo-en.png';

// 鲁班出海学院
const seaSchoolLink = 'https://luban-academy.bluemediagroup.cn/';
const lubanNewCollectLogo = `${CDN_URL}smb_web/image/luban_logo_2023_6_8.png`;

// 官网
const homePages = [
  '/',
  '/index',
  '/about',
  '/partner',
  '/guide',
  // 老鲁班的重定向的页面
  '/about/index',
  '/about/guide',
  '/luban-partner',
  '/luban-partner/list',
]
// 不需要登录的页面
const noLoginPages = [
  // 登录
  '/user',
  '/user/login',
  '/user/forget',
  '/user/reset',
  '/user/register',
  '/user/active',
  // 协议
  '/protocol',
  '/protocol/recharge',
  '/protocol/info-collection',
  '/protocol/advertising',  
  // 404
  '/404',
  ...homePages,
]

// 公众号
const qrcode = `${CDN_URL}assets/qrcode.png`;
// 身份证正则
const idCardPattern = /^\d{17}[\dX]$/
// 银联账户正则
const unionAccountPattern = /^\d{12,19}$/
// 手机号正则
const phonePattern = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/


export {
  walletIcon,
  facebookIcon,
  facebookRadiusIcon,
  alipayIcon,
  wechatIcon,
  unionPayIcon,
  payoneerIcon,
  googleIcon,
  tiktokIcon,
  snapchatIcon,
  alipay,
  wechat,
  unionPay,
  payoneer,
  businessLoadingFailed,
  pingpong,
  pingpongVa,
  facebookOpenAccountTip,
  tiktokOpenAccountTip,
  googleOpenAccountTip,
  emptyIcon,
  adRefuse,
  seaSchoolLink,
  lubanNewCollectLogo,
  homePages,
  noLoginPages,
  qrcode,
  logo,
  logoEn,
  idCardPattern,
  unionAccountPattern,
  phonePattern
};
