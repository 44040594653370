import { Button, Input, Statistic } from 'antd';
import styles from './smsCodeFormItem.less';
import { useRequest } from 'ahooks';
import { getCaptcha } from '@/services/user';
import { FormInstance } from 'antd/es/form/Form';
import { fetchSuccess } from '@/utils/utils';
import { successMessage } from '@/utils/config';
import { useState } from 'react';
import { SmsCodeType } from '@/pages/User/interface';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import useCaptcha from '@/hooks/useCaptcha';

const { Countdown } = Statistic;

interface FormItemProps {
  value?: string;
  onChange?: (checked: string) => void;
  form: FormInstance;

  code_type: SmsCodeType; // 验证码类型
  dependency: string; // 依赖字段
  className?: string;
}

const SmsCodeFormItem: React.FC<FormItemProps> = ({
  form,
  value,
  onChange,
  code_type,
  dependency,
  className,
}) => {
  const { t } = useTranslation();
  // 获取验证码倒计时
  const [isCountDown, setIsCountDown] = useState(false);
  // 腾讯云验证码加载中状态
  const [captchaLoading, setCaptchaLoading] = useState(false);
  const { loading, runAsync } = useRequest(getCaptcha, { manual: true });
  // 引入验证码
  const captchaInit = useCaptcha();

  const captcha = async () => {
    try {
      await form.validateFields([dependency]);
      // 设置腾讯云验证码加载中状态
      setCaptchaLoading(true);

      // 腾讯云验证码初始化
      captchaInit({
        loadSuccessCb: () => {
          setCaptchaLoading(false);
        },
        // 腾讯云验证码验证
        verifyCaptchaFunc: async ({ ticket, randstr }) => {
          const res = await runAsync({
            phone: form.getFieldValue(dependency),
            code_type,
            ticket,
            randstr,
          });
          // 腾讯云验证码验证成功
          if (fetchSuccess(res)) {
            successMessage(
              t('the_verification_code_has_been_sent_please_check_it'),
            );
            setIsCountDown(true);
          }
          return res;
        },
      });
    } catch (e) {}
  };

  return (
    <div className={classNames(styles['sms-code-content'], className)}>
      <Input
        placeholder={t('please_enter_the_verification_code')}
        suffix={
          <Button
            size="small"
            type="primary"
            onClick={captcha}
            loading={loading || captchaLoading}
            disabled={isCountDown}
            className="fx-v-c"
          >
            {isCountDown ? (
              <Countdown
                format="ss"
                value={Date.now() + 60 * 1000}
                onFinish={() => setIsCountDown(false)}
                valueStyle={{ fontSize: 14, color: '#fff' }}
                suffix={`s ${t('resend')}`}
              />
            ) : (
              t('get_verification_code')
            )}
          </Button>
        }
        size="small"
        onChange={(e) => {
          onChange?.(e.target.value);
        }}
        value={value}
      />
    </div>
  );
};

export default SmsCodeFormItem;
